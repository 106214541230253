// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-home-jsx": () => import("./../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-coding-jsx": () => import("./../src/pages/portfolio/coding.jsx" /* webpackChunkName: "component---src-pages-portfolio-coding-jsx" */),
  "component---src-pages-portfolio-design-jsx": () => import("./../src/pages/portfolio/design.jsx" /* webpackChunkName: "component---src-pages-portfolio-design-jsx" */),
  "component---src-pages-portfolio-index-js": () => import("./../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-photography-jsx": () => import("./../src/pages/portfolio/photography.jsx" /* webpackChunkName: "component---src-pages-portfolio-photography-jsx" */)
}

